import React from 'react';
import Header from './common/Header';
import Footer from './common/Footer';
import SEO from './common/SEO';
import logo from '../assets/images/hqz-logo.png';
import bgvid from '../assets/videos/hqz-about-2.mp4';

const About = () => {
  return (
    <>
        <SEO title="About Us - HQZ" />
        <div className="position-relative min-vh-100">
            <video width="320" height="240" className="object-fit-cover w-100 h-100 position-absolute top-0 start-0" autoPlay loop muted playsInline>
                <source src={bgvid} type="video/mp4" />
            </video>
            <Header />
            <div className="container d-flex justify-content-center position-relative align-items-center h-100 py-5">
                <div className="mw-600">
                    <img className="mw-300 mx-auto d-block mb-3" src={logo} alt="" />
                    <div className="px-3">
                        <p className="mb-3 fs-4 lh-base text-white text-start fw-medium">
                            Headquarterz™ is an interactive video sharing app that allows users to upload, share and watch 
                            short-form videos. We are on a mission to empower the next generation of content creators to earn 
                            more, gain greater exposure, and create real-world opportunities from sharing their original 
                            videos with the world.
                        </p>
                        <p className="mb-3 fs-4 lh-base text-white fw-medium text-center">
                            Welcome to Headquarterz!!
                        </p>
                    </div>
                </div>
            </div>
        </div>
        <Footer />
    </>
  );
};

export default About;