import React from 'react';
import Header from './common/Header';
import SEO from './common/SEO';
import logo from '../assets/images/hqz-logo.png';
import featuredavatars from '../assets/images/hqzfeatured.png';
import appledownload from '../assets/images/appstore.png';
import androiddownload from '../assets/images/playstore.png';
import bgvid from '../assets/videos/home-vid-2.mp4';

const Home = () => {
  return (
    <>
        <SEO />
        <div className="position-relative vh-100 overflow-hidden main-page">
            <video width="320" height="240" className="object-fit-cover w-100 h-100 position-absolute top-0 start-0" autoPlay loop muted playsInline>
                <source src={bgvid} type="video/mp4" />
            </video>
            <div class="gradient-overlay"></div>
            <Header />
            <div className="container-fluid d-flex flex-column justify-content-center position-relative align-items-center h-100 p-0 z-3">
                <div className="w-100 text-center text-md-end container">
                    <img className="mw-300 mb-3" src={logo} alt="" />
                </div>
                <div className="w-100 text-end my-3">
                    <div className="ticker-container">
                        <div className="ticker-content">
                            <img src={featuredavatars} alt="HQZ Featured" />
                            <img src={featuredavatars} alt="HQZ Featured" />
                            <img src={featuredavatars} alt="HQZ Featured" />
                            <img src={featuredavatars} alt="HQZ Featured" />
                        </div>
                    </div>
                </div>
                <div className="w-100 text-center text-md-end container">
                    <div className="px-3">
                        <p className="mb-3 text-white fw-medium fs-5 d-none d-md-block">
                            Launch a channel or subscribe to your favorite<br></br>
                            <strong>creators for Free, Download Today</strong>
                        </p>
                        <p className="mb-3 text-white fw-medium fs-5 d-block d-md-none">
                            Launch a channel or subscribe to your favorite <strong>creators for Free, Download Today</strong>
                        </p>
                        <div className="d-flex gap-2 justify-content-end">
                            <a href="https://apps.apple.com/zm/app/hqz/id6445936014?platform=iphone" target="_blank" rel="noreferrer" aria-label="Download HQZ iOS App">
                                <img src={appledownload} alt="Download HQZ iOS App" width="180px" className="mw-100" />
                            </a>
                            <a href="https://play.google.com/store/apps/details?id=com.hqz.app" target="_blank" rel="noreferrer" aria-label="Download HQZ Android App">
                                <img src={androiddownload} alt="Download HQZ Android App" width="180px" className="mw-100" />
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>
  );
};

export default Home;